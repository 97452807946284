<template>
  <div id="consentManagementContainer">

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novo kampanijo" @click.stop="addConsent()"></vs-button>

    <h1>Upravljanje s soglasji</h1>



    <div class="vx-card mt-10">
      <div class="vx-card__header">
        <div class="vx-card__title">
          <h3>Soglasja za uporabnike storitve</h3>
        </div>
      </div>
      <div class="vx-card__collapsible-content">
        <div class="vx-card__body">

          <vs-table :data="customerConsents">

            <template slot="thead">
              <vs-th>id</vs-th>
              <vs-th style="width: 240px;">ime</vs-th>
              <vs-th style="width: 120px;">podpisnik</vs-th>
              <vs-th style="width: 30px;">obvezen</vs-th>
              <vs-th>besedilo</vs-th>
              <vs-th style="min-width: 200px;">tip veljavnosti</vs-th>
              <vs-th style="min-width: 200px;">let veljavnosti</vs-th>
              <vs-th style="width: 120px;">možnosti podelitve</vs-th>
              <vs-th>akcije</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="data[indextr].consent_key">
                  {{ data[indextr].consent_key }}
                </vs-td>

                <vs-td :data="data[indextr].consent_name">
                  <vs-chip transparent color="primary">{{ data[indextr].consent_name }}</vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].consent_giver_type">
                  {{ data[indextr].consent_giver_type }}
                </vs-td>

                <vs-td :data="data[indextr].required">
                  <vs-avatar v-if="data[indextr].required" color="success" icon="check" />
                  <vs-avatar v-if="!data[indextr].required" icon="close" />
                </vs-td>

                <vs-td :data="data[indextr].description">
                  {{ data[indextr].description }}
                </vs-td>

                <vs-td :data="data[indextr].validity_type">
                  {{ data[indextr].validity_type }}
                </vs-td>

                <vs-td :data="data[indextr].validity_years">
                  {{ data[indextr].validity_years ? data[indextr].validity_years : 'n/a' }}
                </vs-td>

                <vs-td :data="data[indextr].confirmation_options">
                  <vs-chip transparent color="dark" v-for="(item, index) in JSON.parse(data[indextr].confirmation_options)" :key="'customer_' + index">
                    {{ item }}
                  </vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].id">

                  <div class="flex">
                    <vs-button color="primary" size="small" icon="edit" @click.stop="editConsent(data[indextr].id)">Uredi</vs-button>
                    <vs-button  class="ml-3" color="danger" size="small" icon="delete">Izbriši</vs-button>
                  </div>

                </vs-td>


              </vs-tr>
            </template>

          </vs-table>

        </div>
      </div>
    </div>


    <div class="vx-card mt-10">
      <div class="vx-card__header">
        <div class="vx-card__title">
          <h3 class="">Soglasja za prostovoljce</h3>
        </div>
      </div>
      <div class="vx-card__collapsible-content">
        <div class="vx-card__body">

          <vs-table :data="volunteerConsents">

            <template slot="thead">
              <vs-th>id</vs-th>
              <vs-th style="width: 240px;">ime</vs-th>
              <vs-th style="width: 120px;">podpisnik</vs-th>
              <vs-th style="width: 30px;">obvezen</vs-th>
              <vs-th>besedilo</vs-th>
              <vs-th style="min-width: 200px;">tip veljavnosti</vs-th>
              <vs-th style="min-width: 200px;">let veljavnosti</vs-th>
              <vs-th style="width: 120px;">možnosti podelitve</vs-th>
              <vs-th>akcije</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="data[indextr].consent_key">
                  {{ data[indextr].consent_key }}
                </vs-td>

                <vs-td :data="data[indextr].consent_name">
                  <vs-chip transparent color="success">{{ data[indextr].consent_name }}</vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].consent_giver_type">
                  {{ data[indextr].consent_giver_type }}
                </vs-td>

                <vs-td :data="data[indextr].required">
                  <vs-avatar v-if="data[indextr].required" color="success" icon="check" />
                  <vs-avatar v-if="!data[indextr].required" icon="close" />
                </vs-td>

                <vs-td :data="data[indextr].description">
                  {{ data[indextr].description }}
                </vs-td>

                <vs-td :data="data[indextr].validity_type">
                  {{ data[indextr].validity_type }}
                </vs-td>

                <vs-td :data="data[indextr].validity_years">
                  {{ data[indextr].validity_years ? data[indextr].validity_years : 'n/a' }}
                </vs-td>

                <vs-td :data="data[indextr].confirmation_options">
                  <vs-chip transparent color="dark" v-for="(item, index) in JSON.parse(data[indextr].confirmation_options)" :key="'customer_' + index">
                    {{ item }}
                  </vs-chip>
                </vs-td>

                <vs-td :data="data[indextr].id">

                  <div class="flex">
                    <vs-button color="primary" size="small" icon="edit">Uredi</vs-button>
                    <vs-button  class="ml-3" color="danger" size="small" icon="delete">Izbriši</vs-button>
                  </div>

                </vs-td>


              </vs-tr>
            </template>

          </vs-table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';



  export default {
    name: 'UpravljanjeSoglasij',

    components: {

    },

    data() {
      return {
        customerConsents: [
          // {
          //   "id": 1,
          //   "name": "customer_personal_data_consent",
          //   "consent_giver": 0,
          //   "description": "Ker želim uporabljati storitev brezplačnih prevozov za starejše \"Sopotniki\", Zavodu Sopotniki in njegovim pooblaščenim osebam (zaposleni, vozniki prostovoljci) dovoljujem hranjenje in vpogled v moje kontaktne podatke, ki obsegajo osebne podatke: telefonsko številko, morebitni elektronski naslov, naslov kraja bivanja ter opombe o mojem zdravstvenem stanju (v kolikor je to potrebno za varno izvedbo storitve prevoza).",
          //   "validityType": "until_revoked",
          //   "validityYears": null,
          //   "confirmationOptions": ["phone", "in_person", "signature", "e-signature"],
          //   "required": true
          // },
          // {
          //   "id": 2,
          //   "name": "customer_photo_consent",
          //   "consent_giver": 0,
          //   "description": "Kot uporabnik storitve brezplačnih prevozov za stareješe \"Sopotniki\" dovoljujem fotografiranje in snemanje med uporabo storitve prevoza oz. pri udeležbi na dogodku, ki se ga udeležujem na povabilo Zavoda Sopotniki.",
          //   "validityType": "until_revoked",
          //   "confirmationOptions": ["phone", "in_person", "signature", "e-signature"],
          //   "validityYears": null,
          //   "required": false
          // },
          // {
          //   "id": 3,
          //   "name": "customer_media_consent",
          //   "consent_giver": 0,
          //   "description": "Kot uporabnik storitve brezplačnih prevozov za stareješe \"Sopotniki\" soglašam z objavo mojih fotografij in video posnetkov, ki so nastali med uporabo storitve prevoza oz. med udeležbi na dogodku, ki se ga udeležujem na povabilo Zavoda Sopotniki v medijih in na socialnih omrežjih.",
          //   "validityType": "until_revoked",
          //   "confirmationOptions": ["phone", "in_person", "signature", "e-signature"],
          //   "validityYears": null,
          //   "required": false
          // },
          // {
          //   "id": 4,
          //   "name": "customer_call_consent",
          //   "consent_giver": 0,
          //   "description": "Kot uporabnik storitve brezplačnih prevozov za stareješe \"Sopotniki\" soglašam, da me predstavniki Zavoda Sopotniki lahko pokličejo na mojo telfonsko številko z namenom posredovanja vabil in informacij o storitvi.",
          //   "validityType": "until_revoked",
          //   "confirmationOptions": ["phone", "in_person", "signature", "e-signature"],
          //   "validityYears": null,
          //   "required": false
          // }
        ],

        volunteerConsents: [
          // {
          //   "id": 1,
          //   "name": "volunteer_personal_data_consent",
          //   "consent_giver": 1,
          //   "description": "Kot prostovoljec v Zavodu Sopotniki soglašam z obdelavo mojih osebnih podatkov, ki obsegajo: telefonsko številko, morebitni elektronski naslov, naslov kraja bivanja ter morebitne opombe (v kolikor je to potrebno za varno izvedbo storitve prevoza) za namene opravljanja prostovoljskega dela.",
          //   "validityType": "limited",
          //   "confirmationOptions": ["signature", "e-signature"],
          //   "validityYears": 1,
          //   "required": true
          // },
          // {
          //   "id": 2,
          //   "name": "volunteer_driving_licence_consent",
          //   "consent_giver": 1,
          //   "description": "Kot prostovoljec v Zavodu Sopotniki soglašam z vpogledom, fotokopiranjem oz. skeniranjem in hranjenjem kopije mojega vozniškega dovoljenja.",
          //   "validityType": "limited",
          //   "confirmationOptions": ["signature", "e-signature"],
          //   "validityYears": 1,
          //   "required": true
          // },
          // {
          //   "id": 3,
          //   "name": "volunteer_bank_account_consent",
          //   "consent_giver": 1,
          //   "description": "Kot prostovoljec v Zavodu Sopotniki soglašam, da pooblaščene osebe Zavoda Sopotniki hranijo in imajo vpogled v številko mojega transakcijskega računa z namenom povrnitve potnih stroškov.",
          //   "validityType": "limited",
          //   "confirmationOptions": ["signature", "e-signature"],
          //   "validityYears": 1,
          //   "required": true
          // },
          // {
          //   "id": 4,
          //   "name": "volunteer_phone_consent",
          //   "consent_giver": 1,
          //   "description": "Kot prostovoljec Zavoda Sopotniki soglašam, da me predstavniki zavoda lahko pokličejo na mojo telfonsko številko z namenom preverjanja moje razpoložljivosti za opravljanje prostovoljskega dela in posredovanja pomemebnih informacij o izvajanju prostovoljskega dela.",
          //   "validityType": "limited",
          //   "confirmationOptions": ["signature", "e-signature"],
          //   "validityYears": 1,
          //   "required": true
          // },
          // {
          //   "id": 5,
          //   "name": "volunteer_email_consent",
          //   "consent_giver": 1,
          //   "description": "Kot prostovoljec Zavoda Sopotniki soglašam, da mi predstavniki oz. informacijski sistem lahko pošiljajo e-poštna sporočila z namenom posredovanja pomemebnih informacij o izvajanju prostovoljskega dela.",
          //   "validityType": "limited",
          //   "confirmationOptions": ["signature", "e-signature"],
          //   "validityYears": 1,
          //   "required": true
          // },
          // {
          //   "id": 6,
          //   "name": "volunteer_photo_consent",
          //   "consent_giver": 1,
          //   "description": "Kot prostovoljec v Zavodu Sopotniki dovoljujem fotografiranje in snemanje med izvanjanjem storitve prevoza oz. pri udeležbi na dogodku, ki se ga udeležujem na povabilo Zavoda Sopotniki.",
          //   "validityType": "until_revoked",
          //   "confirmationOptions": ["phone", "in_person", "signature", "e-signature"],
          //   "validityYears": null,
          //   "required": false
          // },
          // {
          //   "id": 7,
          //   "name": "volunteer_media_consent",
          //   "consent_giver": 1,
          //   "description": "Kot prostovoljev v Zavodu Sopotniki soglašam z objavo mojih fotografij in video posnetkov, ki so nastali med izvanjanjem storitve prevoza oz. med udeležbo na dogodku na povabilo Zavoda Sopotniki v medijih in na socialnih omrežjih.",
          //   "validityType": "until_revoked",
          //   "confirmationOptions": ["phone", "in_person", "signature", "e-signature"],
          //   "required": false
          // }
        ],
      }
    },

    mounted() {
      const _this = this;

      _this.getConsents();
    },

    computed: {


    },

    watch: {

    },

    methods: {
      getConsents() {
        const _this = this;

        _this.$vs.loading();

        Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'consent-management')
          .then((res) => {

            //_this.donorTypes = res.data.data;

            console.log("consents: ", res.data.data);

            _this.customerConsents = _.filter(res.data.data, {consent_giver_type: 'CUSTOMER'});
            _this.volunteerConsents = _.filter(res.data.data, {consent_giver_type: 'VOLUNTEER'});

            _this.$vs.loading.close();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      addConsent() {
        const _this = this;

        _this.$router.push({name: 'Soglasje', params: {consentId: null}});
      },

      editConsent(consentId) {
        const _this = this;

        _this.$router.push({name: 'Soglasje', params: {consentId: consentId}});
      }



    }
  }
</script>

